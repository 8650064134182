@import "../mixin/color.less";

#HomeLayout {
  width: 100%;
  height: 100%;
  display: flex;
  #leftWrapper {
    // width: 230px;
    display: flex;
  }
  #menuList {
    overflow-x: hidden;
    overflow-y: hidden;
    &:hover {
      overflow-y: overlay;
    }
    &::-webkit-scrollbar {
      width: 5px !important;
      border-radius: 4px;
      background: transparent !important;
      opacity: 0.5 !important; 
    }

    &::-webkit-scrollbar-button {
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: #C2EBE8 !important;
      border: unset !important;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
    }
    ::-webkit-scrollbar-corner {
      background: #000;
    }
  }
}

