@import "./mixin/color.less";

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", Arial, sans-serif;
}

.scrollList {
  overflow-x: hidden;
  overflow-y: hidden;
  &:hover {
    overflow-y: overlay;
  }
  &::-webkit-scrollbar {
    width: 10px !important;
    border-radius: 4px;
    background: transparent !important;
    opacity: 0.5 !important; 
  }

  &::-webkit-scrollbar-button {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #C2EBE8 !important;
    border: unset !important;
  }

  &::-webkit-scrollbar-track {
    box-shadow: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: #000;
  }
}


#formInput {
  border-width: 2px !important;
  border-color: transparent !important;
  &:focus{
    border-color: #13D1AC !important;
  }
  &:hover{
    border-color: #13D1AC !important;
  }
}

.ant-form-item-explain-error {
  padding-left: 16px;
  margin-top: 4px;
}

.pagination {
  .ant-pagination-item-link {
    width: 40px !important;
    height: 40px!important;
    border-radius: 20px!important;
    border: 1px solid #86C8BE!important;
    background-color: #DAF1DD!important;
    line-height: 40px!important;
    color: #000!important;
    display: flex;
    justify-content: center;
    .ant-pagination-item-container {
      width: 40px;
    }
  }
  .ant-pagination-item {
    min-width: 40px !important;
    height: 40px!important;
    border-radius: 20px!important;
    border: 1px solid #86C8BE!important;
    background-color: #DAF1DD!important;
    line-height: 40px!important;
    &:hover {
      background-color: #42C1B4!important;
      a {
        color: white!important;
      }
    }
  }
  .ant-pagination {
    display: flex!important;
    justify-content: center!important;
  }
  .ant-pagination-item-active {
    background-color: #42C1B4!important;
    a {
      color: white!important;
    }
  }
  .ant-select-selector {
    height: 40px !important;
    width: 112px !important;
    border-radius: 20px !important;
    background-color: #DAF1DD!important;
    border: 1px solid #86C8BE!important;
    line-height: 40px !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;
  }
}

.dark-checkbox {
  .ant-checkbox-inner {
    background-color: #2D2D2D !important;
    border: 1px solid #00FF2D !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: white !important;
  }
}
.light-checkbox {
  .ant-checkbox-inner {
    background-color: rgba(252,252,252,0.5) !important;
    border: 1px solid #00846A !important;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #42C1B4 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after, .ant-checkbox-inner::after {
    border: 2px solid #42C1B4;;
    border-top: 0;
    border-left: 0;
  }
}

.ant-switch-checked {
  background-color: #00B290 !important;
}
audio {
  width: 300px !important;
  height: 54px !important;
}
