#formTextarea {
  border-width: 2px !important;
  border-color: transparent !important;
  &:focus{
    border-color: #13D1AC !important;
  }
  &:hover{
    border-color: #13D1AC !important;
  }
}
