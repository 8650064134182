#searchInput {
  border: 0px;
}
#searchInput.ant-input {
  height: 50px !important;
}
.ant-input-group-addon {
  background-color: white;
  border: 0px;
}
.ant-input-search-button {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  overflow: hidden !important;
  border-left: 1px solid #9F9F9F !important;
}
.ant-input-group-wrapper {
  border-radius: 15px;
  width: 260px !important;
  overflow: hidden;
  box-shadow: inset 0px 3px 6px rgba(0,0,0,0.16);
}
