

  .ant-select-selector {
    width: 100% !important;
    height: 50px !important;
    border-radius: 15px !important;
    line-height: 50px !important;
    border-color: transparent !important;
    &:hover {
      border-color:#13D1AC !important;
    }
  }
  .ant-select-selection-placeholder {
    line-height: 50px !important;
  }

.formInputSelect {
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color:#13D1AC !important;
  }
  .ant-select-:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #13D1AC !important;
  }
  .ant-select-selection-item {
    line-height: 50px !important;
  }
  .ant-select-selection-placeholder {
    line-height: 50px !important;
  }
  .ant-select-item-options {
    background-color: #A5E3CD !important;
  }

  .ant-select-item-option-content {
    color: #125346;
  }
  .ant-select-selection-item {
    color: #125346;
  }
}

.multipleFormSelectList {
  .ant-select-selector {
    width: 100% !important;
    min-height: 50px !important;
    border-radius: 15px !important;
    line-height: 50px !important;
    padding: 0px 16px !important;
  }
  .ant-select-selection-item {
    line-height: 50px !important;
  }

  .ant-select-selection-overflow-item {
    min-height: 50px !important;
  }
  .ant-select-selection-item {
    min-height: 50px !important;
    padding: 0px 4px !important;
    line-height: 50px !important;
    border-radius: 15px !important;
    margin-right: 0px 4px 0px 0px !important;
    background-color: #159E90 !important;
  }
  .ant-select-selection-item-content {
    margin: 0px !important;
    min-height: 50px !important;
    padding: 0px 4px !important;
    line-height: 50px !important;
    font-size: 18px !important;
    color: white !important;
  }
  .ant-select-selection-item-remove {
    margin: 0px !important;
    min-height: 50px !important;
    padding: 0px 4px !important;
    line-height: 50px !important;
    font-size: 18px !important;
    color: white !important;
  }
}
