.bf-container  {
    border-width: 2px !important;
    border-color: transparent !important;
    &:focus{
      border-color: #13D1AC !important;
    }
    &:hover{
      border-color: #13D1AC !important;
    }
  }

.BraftEditor {
  .bf-video-wrap {
    width: 300px;
    height: 200px;
  }
  .bf-player-holder{
    width: 100%;
  }
  .bf-player-holder.audio{
    width: 100%;
    height: 160px !important;
  }
  .bf-audio-wrap {
    width: 160px;
    height: 160px;
  }
}
