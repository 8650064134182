.login_password {
  span, .ant-input-affix-wrapper, input  {
    background-color: #44474B !important;
    color: white;
    border-radius: 15px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  background-color: #44474B !important;
  color: white !important;
}
input:-webkit-autofill{
  background-color: #44474B !important;
  color: white !important;
}
.input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}
