#Layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  // background-color: #313131;
  display: flex;
  align-items: center;
  justify-content: center;
}

