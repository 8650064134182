.dark_member {
  .ant-drawer-content {
    // background-: linear-gradient(to right, #9DC5C1, #D8FCF8);
    // padding: 0px 24px !important;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    background-image: url(../assets/drawer_bg_dark.png);
    backdrop-filter: blur(30px) brightness(0.65);
    background-color: unset !important;
    opacity: 0.98;
    // background-color: transparent !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
    overflow-x: hidden !important;
  }
  .ant-drawer-wrapper-body {
    // background: linear-gradient(to right, #9DC5C1, #D8FCF8);
    padding: 0px 24px !important;
    // background-color: transparent;
  }
  .ant-drawer-content-wrapper {
    width: 602px !important;
    background-color: transparent;
  }
}
.light_member {
  .ant-drawer-content {
    // background-: linear-gradient(to right, #9DC5C1, #D8FCF8);
    // padding: 0px 24px !important;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    background-image: url(../assets/drawer_bg.png);
    backdrop-filter: blur(30px) brightness(1.5);
    background-color: unset !important;
    opacity: 0.98;
    // background-color: transparent !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
    overflow-x: hidden !important;
  }
  .ant-drawer-wrapper-body {
    // background: linear-gradient(to right, #9DC5C1, #D8FCF8);
    padding: 0px 24px !important;
    // background-color: transparent;
  }
  .ant-drawer-content-wrapper {
    width: 602px !important;
    background-color: transparent;
  }
}


.scrollList {
  overflow-x: hidden;
  overflow-y: hidden;
  &:hover {
    overflow-y: overlay;
  }
  &::-webkit-scrollbar {
    width: 10px !important;
    border-radius: 4px;
    background: transparent !important;
    opacity: 0.5 !important; 
  }

  &::-webkit-scrollbar-button {
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track-piece {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #C2EBE8 !important;
    border: unset !important;
  }

  &::-webkit-scrollbar-track {
    box-shadow: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: #000;
  }
}

#school {

  .ant-pagination-item-link {
    width: 40px;
    height: 40px;
    border-radius: 15px;
    border: 1px solid #86C8BE;
    background-color: #DAF1DD;
    line-height: 40px;
    color: #000;
  }
  .ant-pagination-item {
    min-width: 40px;
    height: 40px;
    border-radius: 15px;
    border: 1px solid #86C8BE;
    background-color: #DAF1DD;
    line-height: 40px;
    &:hover {
      background-color: #42C1B4;
      a {
        color: white;
      }
    }
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
  .ant-pagination-item-active {
    background-color: #42C1B4;
    a {
      color: white;
    }
  }
  .ant-select-selector {
    height: 40px !important;;
    width: 112px;
    border-radius: 15px;
    background-color: #DAF1DD;
    border: 1px solid #86C8BE;
    line-height: 40px !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;;
  }
}
