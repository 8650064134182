#dark_tag {
  .ant-drawer-content {
    // background-: linear-gradient(to right, #9DC5C1, #D8FCF8);
    // padding: 0px 24px !important;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    background-image: url(../assets/drawer_bg_dark.png);
    backdrop-filter: blur(30px) brightness(0.65);
    background-color: unset !important;
    opacity: 0.98;
    // background-color: transparent !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
  }
  .ant-drawer-wrapper-body {
    // background: linear-gradient(to right, #9DC5C1, #D8FCF8);
    padding: 0px 24px !important;
    // background-color: transparent;
  }
  .ant-drawer-content-wrapper {
    width: 602px !important;
    background-color: transparent;
  }
}
#light_tag {
  .ant-drawer-content {
    // background-: linear-gradient(to right, #9DC5C1, #D8FCF8);
    // padding: 0px 24px !important;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    background-image: url(../assets/drawer_bg.png);
    backdrop-filter: blur(30px) brightness(1.5);
    background-color: unset !important;
    opacity: 0.98;
    // background-color: transparent !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
  }
  .ant-drawer-wrapper-body {
    // background: linear-gradient(to right, #9DC5C1, #D8FCF8);
    padding: 0px 24px !important;
    // background-color: transparent;
  }
  .ant-drawer-content-wrapper {
    width: 602px !important;
    background-color: transparent;
  }
}
