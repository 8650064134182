.ant-table-pagination {
  margin: 16px 0px 0px 0px !important;
}

.pin {
  background-color: black;
  color: white;
}

.pin td {
  background-color: black !important;
}

.pin .ant-progress-text {
  color: white;
}

.done {
  background-color: #999999;
  color: white;
}

.done td {
  background-color: #999999 !important;
}

.done .ant-progress-text {
  color: white;
}
#DataTable  {
  .ant-input-group-wrapper {
    border-radius: 15px;
    width: 240px !important;
    overflow: hidden;
    box-shadow: inset 0px 3px 6px rgba(0,0,0,0.16);
  }
  #searchInput {
    border: 0px;
  }
  #searchInput.ant-input {
    height: 50px !important;
    // border-top-left-radius: 15px;
    // border-bottom-left-radius: 15px;
  }
  .ant-input-group-addon {
    background-color: white;
    border: 0px;
  }
  .ant-input-search-button {
    background-color: transparent;
    border: 0px;
    box-shadow: none;
    overflow: hidden;
    border-left: 1px solid #9F9F9F;
  }
  th.ant-table-cell {
    background-color: #002C51;
    color: white;
    height: 70px;
    border-bottom: 0px;
    border: 0px !important;
  }
  th.ant-table-cell:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  th.ant-table-cell:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border: 0px !important;
  }
  .ant-table {
    background-color: transparent !important;
  }
  .dark-checkbox {
    .ant-checkbox-inner {
      background-color: #2D2D2D !important;
      border: 1px solid #00FF2D !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: white !important;
    }
  }
  .light-checkbox {
    .ant-checkbox-inner {
      background-color: rgba(252,252,252,0.5) !important;
      border: 1px solid #00846A !important;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #42C1B4 !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after, .ant-checkbox-inner::after {
      border: 2px solid #42C1B4;;
      border-top: 0;
      border-left: 0;
    }
  }
  .ant-pagination-item-link {
    width: 40px;
    height: 40px;
    border-radius: 15px;
    border: 1px solid #86C8BE;
    background-color: #DAF1DD;
    line-height: 40px;
    color: #000;
  }
  .ant-pagination-item {
    min-width: 40px;
    height: 40px;
    border-radius: 15px;
    border: 1px solid #86C8BE;
    background-color: #DAF1DD;
    line-height: 40px;
    &:hover {
      background-color: #42C1B4;
      a {
        color: white;
      }
    }
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
  }
  .ant-pagination-item-active {
    background-color: #42C1B4;
    a {
      color: white;
    }
  }
  .ant-select-selector {
    height: 40px !important;;
    width: 112px;
    border-radius: 15px;
    background-color: #DAF1DD;
    border: 1px solid #86C8BE;
    line-height: 40px !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;;
  }
}
